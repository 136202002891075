const API_BASE = 'https://one-link.onrender.com'
const API_CONSTANT = {
  API_BASE: API_BASE + '/',
  OUR_BASE_URL: 'https://one.infielight.com/',
  API_USER_LOGIN: API_BASE + '/user',
  API_USER_ME: API_BASE + '/user/me',
  API_CREATE_LINK: API_BASE + '/link',
  API_GET_LINKS: API_BASE + '/links',
  API_DELETE_LINK: API_BASE + '/link',
  API_GET_SINGLE_LINK: API_BASE + '/link/',
}

export default API_CONSTANT
